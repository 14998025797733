import request from "@/utils/request";

/**
 * @return Promise
 */
export function getDiscountCode(dcKind, isinit, useFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/product-dc-code/info",
    method: "get",
    params: {
      dcKind,
      isinit,
      useFlag,
    },
  });
}

/**
 * @return Promise
 */
export function putDiscountCode(addedRecords, changedRecords, deletedRecords) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/product-dc-code/info",
    method: "put",
    data: {
      addedRecords,
      changedRecords,
      deletedRecords,
    },
  });
}
