<template>
  <ejs-dialog
    ref="discountCodeImagePopup"
    :isModal="false"
    width="180"
    :animationSettings="animationSettings"
    @close="closePopup"
  >
    <div
      v-for="dcCodeImage in dcCodeImage"
      v-bind:key="dcCodeImage.imageId"
      :id="dcCodeImage.imageId"
      @click="onClickDcCodeImage(dcCodeImage)"
    >
      <img :src="dcCodeImage.imagePath" />
    </div>
  </ejs-dialog>
</template>

<script>
export default {
  name: "DiscountCodeImagePopup",
  watch: {
    $route() {
      this.$refs.discountCodeImagePopup.hide();
    },
  },
  props: ["position", "dcCodeImageList"],
  data() {
    return {
      animationSettings: { effect: "None" },
      dcCodeImage: this.$props.dcCodeImageList,
      rowIndex: 0,
    };
  },
  methods: {
    showPopup(rowIndex) {
      this.rowIndex = rowIndex;
      this.$refs.discountCodeImagePopup.show();
    },
    closePopup() {
      this.$emit("popupClosed");
    },
    onClickDcCodeImage(dcCodeImage) {
      this.$emit("popupConfirmed", dcCodeImage, this.rowIndex);
      this.$refs.discountCodeImagePopup.hide();
    },
  },
};
</script>
