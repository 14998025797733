<template>
  <div class="content-wrapper">
    <div class="content-lookup" @click.self="closeDcCodeImage">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">할인 종류</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  :dataSource="searchOptions.dcKindOptionsDropdown"
                  v-model="searchConditions.dcKind"
                  :allowFiltering="false"
                  :fields="commonCodeFields"
                  cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        v-model="searchConditions.useFlag"
                      />
                      <i></i>
                      <div class="label">사용여부</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onViewDcCode"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">할인코드 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button" style="margin-right: 140px">
                <li class="save keyColor">
                  <erp-button
                      button-div="SAVE"
                      :is-key-color="true"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click.native="onSaveDcCode"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="discountCodeRegistration.shortcuts.addDiscountCode"
                      :shortcut="{key: 'F3'}"
                      :disabled="isModifyMode"
                      @click.native="onAddDcCode"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :disabled="isModifyMode"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click.native="onDeleteDcCode"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
              <div class="header-switch">
                <div class="title">
                  멀티 편집모드
                </div>
                <div class="switch">
                  <ejs-switch
                    v-model="isModifyMode"
                    :checked="isModifyMode"
                    @change="onModifySwitchChanged"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="dcCodeGrid"
                v-bind="dcCodeGridOption"
                :selectionSettings="selectionSettings"
                :validationRules="GridValidationRules"
                :allowFiltering="!isModifyMode"
                :allowSorting="!isModifyMode"
                :isAutoSelectCell="!isModifyMode"
                @recordClick="recordClick"
                @cellSaved="cellSaved"
                @headerCellInfo="headerCellInfo"
                @queryCellInfo="queryCellInfo"
                @actionComplete="dcCodeGridActionComplete"
                @cellSelected="dcCodeGridCellSelected"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <discount-code-image-popup
      v-if="isVisibleDiscountCodeImagePopup"
      ref="discountCodeImagePopup"
      :dcCodeImageList="dcCodeImageList"
      @popupClosed="closeDcCodeImage"
      @popupConfirmed="confirmDcCodeImage"
    />
    <edit-multiple-columns-popup
      v-if="isEditMultipleColumnsPopupOpen"
      ref="editMultipleColumnsPopup"
      @popupConfirm="onEditMultipleColumnsPopupConfirm"
      @popupClosed="onEditMultipleColumnsPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import Vue from "vue";
import { maxBy as _maxBy } from "lodash";
import {
  getDiscountCode,
  putDiscountCode,
} from "@/api/discountCodeRegistration";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import {
  Edit,
  ForeignKey,
  Selection,
  Resize,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";
import discountCodeImagePopup from "../popup/DiscountCodeImagePopup";
import editMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';
import commonMixin from "@/views/layout/mixin/commonMixin";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "DiscountCodeRegistration",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    discountCodeImagePopup,
    editMultipleColumnsPopup,
    ejsGridWrapper,
    ErpButton,
  },
  async created() {
    this.searchOptions.dcKindOptionsDropdown.unshift({
      comCode: "",
      comName: "전체",
    });
    await this.getDiscountCode();
    this.searchConditions.isInit = false;
  },
  data() {
    return {
      count: 0,
      grid: [Edit, ForeignKey, Selection, Resize],
      imageTemplate() {
        return {
          template: Vue.component("imageTemplate", {
            template: `<img :src="image" style="height: 16px;"/>`,
            data() {
              return {
                data: {},
              };
            },
            computed: {
              image() {
                return this.data.imagePath;
              },
            },
          }),
        };
      },
      commonCodeFields: { text: "comName", value: "comCode" },
      dcCodeInfo: [],
      dcCodeImageList: [],
      searchConditions: {
        dcKind: "",
        useFlag: true,
        isInit: true,
      },
      searchOptions: {
        dcKindOptionsDropdown: commonCodesGetCommonCode("DC_KIND"),
        dcKindOptionsGrid: commonCodesGetCommonCode("DC_KIND"),
        dcMethodOptions: commonCodesGetCommonCode("DC_METHOD").slice(0, 2), // 할인금액, 할인율만 사용함
      },
      selectionSettings: {
        type: "Multiple",
        mode: "Both",
        enableToggle: false,
      },
      GridValidationRules: {
        dcAmt: {
          custom: {
            method: (args) => {
              const { dcMethod, dcAmt } = args;
              return !(dcMethod === "RATE" && (dcAmt < 1 || dcAmt > 100));
            },
            message: "main.validationMessage.rangeMessage",
            messageValue: [1, 100],
          },
        },
      },
      isVisibleDiscountCodeImagePopup: false,
      isEditMultipleColumnsPopupOpen: false,
      dcCodeInfoLength: 0,
      isModifyMode: false,
    };
  },
  computed: {
    isPopupOpened() {
      return (
        this.isVisibleDiscountCodeImagePopup ||
        this.isEditMultipleColumnsPopupOpen
      );
    },
    dcCodeGridOption() {
      return {
        provides: [Edit, ForeignKey, Selection, Resize, ExcelExport],
        dataSource: this.dcCodeInfo,
        allowResizing: true,
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        columns: [
          {
            field: "dcId",
            isPrimaryKey: true,
            allowEditing: false,
            visible: false,
          },
          {
            field: "dcKind",
            headerText: "할인종류",
            width: 120,
            textAlign: "Left",
            isCommonCodeField: true,
            editType: "dropdownedit",
            groupCode: 'DC_KIND',
            allowEditing: true,
            type: "string",
          },
          {
            field: "dcContents",
            headerText: "할인내용",
            width: 300,
            type: "string",
            allowEditing: true,
            textAlign: "Left",
            maxLength: 100,
            isRemarks: true,
          },
          {
            field: "dcMethod",
            headerText: "할인방법",
            width: 120,
            textAlign: "Left",
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: 'DC_METHOD',
            groupCodeExclude: ['PAYMT'],
            allowEditing: true,
            type: "string",
          },
          {
            allowEditing: true,
            isNumericType: true,
            field: "dcAmt",
            inputNumberProperty: { maxLength: 13 },
            headerText: "할인금액(율)",
            textAlign: "right",
            type: "number",
            width: 150,
            multiEdit: {
              allowDot: false,
              allowMinus: true,
              displayComma: false,
            },
          },
          {
            field: "sortNo",
            headerText: "정렬",
            minWidth: 16,
            width: 45,
            textAlign: "center",
            allowEditing: true,
            allowFiltering: false,
            type: "number",
            isNumericType: true,
            inputNumberProperty: { maxLength: 3 },
            multiEdit: {
              min: 1,
              max: 999,
              propMaxLength: 3,
              allowDot: false,
              allowMinus: false,
              displayComma: false,
            },
          },
          {
            field: "useFlag",
            headerText: "사용",
            minWidth: 16,
            width: 65,
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            textAlign: "Center",
          },
          {
            field: "remarks",
            headerText: "비고",
            type: "string",
            allowEditing: true,
            textAlign: "Left",
            maxLength: 400,
            isRemarks: true,
          },
          { field: "imageId", visible: false },
          // {
          //   field: "imagePath",
          //   headerText: "이미지",
          //   type: "string",
          //   allowEditing: false,
          //   allowSorting: false,
          //   allowFiltering: false,
          //   textAlign: "Center",
          //   template: this.imageTemplate
          // }
        ],
        validationRules: {
          dcKind: {
            required: true,
          },
          dcContents: {
            required: true,
          },
          dcMethod: {
            required: true,
          },
        },
      };
    },
  },
  methods: {
    numberWithCommas,
    headerCellInfo(args) {
      const {
        cell: {
          column: {
            field,
            headerText,
          },
        },
        node,
      } = args;
      if (
        field === "dcKind" ||
        field === "dcContents" ||
        field === "dcMethod"
      ) {
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (this.isModifyMode && headerText !== 'NO') {
        const allowedEditColumns = this.dcCodeGridOption.columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
        }
      }
    },
    async onGridHeaderClicked(column) {
      if (!column?.field) {
        return;
      }
      const gridRefs = this.$refs.dcCodeGrid;
      const selectedRowIndexes = [...new Array(gridRefs?.getBatchCurrentViewRecords().length).keys()];
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
    queryCellInfo(args) {
      const {
        cell,
        column: { field },
        data,
      } = args;
      if (field === "imagePath" && !data.imagePath) {
        cell.classList.add(this.$t("className.grid.clickArea"));
        cell.innerText = "추가";
      }
      if (
        field === "dcKind" ||
        field === "dcMethod" ||
        field === "dcContents" ||
        field === "dcAmt" ||
        field === "sortNo" ||
        field === "remarks" ||
        field === "useFlag"
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (field === "dcAmt") {
        cell.innerText = `${this.numberWithCommas(data?.dcAmt)}${
          data?.dcMethod === "RATE" ? "%" : ""
        }`;
      }
      if (args.column && !data.useFlag) {
        cell.style.textDecoration = "line-through";
        cell.style.textDecorationColor = "red";
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.dcCodeGridOption.columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
        if (!allowedEditColumns.includes(field)) {
          cell.style.backgroundColor = '#f9f9f9';
          cell.style.pointerEvents = 'none';
        }
      }
    },
    recordClick(args) {
      if (args.column && args.column.field === "imagePath") {
        this.isVisibleDiscountCodeImagePopup = true;
        this.$nextTick(() => {
          this.$refs.discountCodeImagePopup.showPopup(args.rowIndex);
        });
      }
    },
    cellSaved(args) {
      const {
        rowData: { dcMethod, dcAmt },
        cell,
        columnName,
        value,
      } = args;
      if (columnName === "dcAmt") {
        cell.innerText = `${this.numberWithCommas(value)}${
          dcMethod === "RATE" ? "%" : ""
        }`;
      }
      if (columnName === "dcMethod") {
        cell.nextSibling.innerText = `${this.numberWithCommas(dcAmt)}${
          value === "RATE" ? "%" : ""
        }`;
      }
    },
    closeDcCodeImage() {
      this.isVisibleDiscountCodeImagePopup = false;
    },
    confirmDcCodeImage(dcCodeImage, rowIndex) {
      this.$refs.dcCodeGrid.updateCell(
        rowIndex,
        "imageId",
        dcCodeImage.imageId
      );
      this.$refs.dcCodeGrid.updateCell(
        rowIndex,
        "imagePath",
        dcCodeImage.imagePath
      );
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.dcCodeGrid.updateCell(
          index,
          field,
          value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
    async onViewDcCode() {
      const batchChanges = this.$refs.dcCodeGrid.getBatchChanges();
      if (
        batchChanges.addedRecords.length > 0 ||
        batchChanges.changedRecords.length > 0 ||
        batchChanges.deletedRecords.length > 0
      ) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      await this.getDiscountCode();
    },
    onAddDcCode() {
      let _rid;
      if (this.dcCodeInfo.length > 0) {
        const currentViewRecords = this.$refs.dcCodeGrid.getBatchCurrentViewRecords();
        _rid = _maxBy(currentViewRecords, "_rid")._rid;
      } else {
        const { addedRecords } = this.$refs.dcCodeGrid.getBatchChanges();
        _rid = addedRecords.length;
      }

      const addRecordObject = {
        _rid: _rid + 1, //NO
        dcKind: null, //*할인종류
        dcContents: null, //*할인내용
        dcMethod: null, //*할인방법
        dcAmt: 0, //할인금액(율)
        useFlag: true, //*사용여부
        remarks: null, //비고
        sortNo: 0, //정렬번호
        imageId: null,
        imagePath: null, //이미지
      };
      this.$refs.dcCodeGrid.addRecord(addRecordObject);

      const currentviewRecords = this.$refs.dcCodeGrid.getBatchCurrentViewRecords();
      const idxToSelect = currentviewRecords.findIndex(
        (record) => record._rid === addRecordObject._rid
      );

      this.$refs.dcCodeGrid.editCell(idxToSelect, "dcKind");

      //이미지 팝업이 열려있는 경우 close
      if (this.isVisibleDiscountCodeImagePopup) {
        this.isVisibleDiscountCodeImagePopup = false;
      }
    },
    async onSaveDcCode() {
      const {
        addedRecords,
        changedRecords,
        deletedRecords,
      } = this.$refs.dcCodeGrid.getBatchChanges();
      if (!this.$refs["dcCodeGrid"].validate()) {
        return;
      }

      await putDiscountCode(
          addedRecords.map((r) => ({
            ...r,
            dcAmt: Number(r.dcAmt ? r.dcAmt.toString().replace(/,/gi, "") : 0),
          })),
          changedRecords.map((r) => ({
            ...r,
            dcAmt: Number(r.dcAmt ? r.dcAmt.toString().replace(/,/gi, "") : 0),
          })),
          deletedRecords.map((r) => ({
            ...r,
            dcAmt: Number(r.dcAmt ? r.dcAmt.toString().replace(/,/gi, "") : 0),
          }))
      );
      this.infoToast(this.$t("main.popupMessage.saved"));
      await this.getDiscountCode();
    },
    onDeleteDcCode() {
      this.$refs.dcCodeGrid.deleteRecord();
      this.infoToast(this.$t("main.popupMessage.deleted"));
    },
    async getDiscountCode() {
      const dcKind =
        this.searchConditions.dcKind === ""
          ? null
          : this.searchConditions.dcKind;
      const isInit = this.searchConditions.isInit; // 첫 진입 시에만 true 설정
      const useFlag = this.searchConditions.useFlag;
      //이미지 팝업이 열려있는 경우 close
      if (this.isVisibleDiscountCodeImagePopup) {
        this.isVisibleDiscountCodeImagePopup = false;
      }
      const response = await getDiscountCode(dcKind, isInit, useFlag);
      const productDcCodeInfoList = response.value.productDcCodeInfoList;
      if (isInit) {
        // 첫 진입 시에만 전체 imageList 팝업에 추가
        this.dcCodeImageList = response.value.dcCodeImageList;
      }
      this.dcCodeInfo = productDcCodeInfoList.map((item, index) => {
        item["dcAmt"] || (item["dcAmt"] = null);
        item["remarks"] || (item["remarks"] = null);
        item["dcAmt"] || (item["dcAmt"] = 0);
        return {
          ...item,
          _rid: index + 1,
        };
      });

      this.dcCodeInfoLength = this.dcCodeInfo.length;
    },
    dcCodeGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.dcCodeGrid?.getGridBatchCount() || 0
      );
    },
    onClickExcel() {
      this.$refs.dcCodeGrid.excelExport();
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.selectionSettings = isChecked
        ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
        : {type: 'Multiple', mode: 'Both', enableToggle: false};
      this.$refs.dcCodeGrid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
    async dcCodeGridCellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      if (selectedRowCellIndex[0]?.cellIndexes.length > 1) {
        this.errorToast('편집할 셀 하나만 선택해주세요.');
        return;
      }
      let columns = [];
      this.dcCodeGridOption.columns
        ?.forEach(column => {
          if (column?.columns) {
            column.columns
              ?.forEach(c => columns.push(c));
          } else {
            columns.push(column);
          }
        });
      const allowedEditColumns = columns
        ?.filter(item => item.allowEditing)
        ?.map(item => item.field);
      const column = columns[cellIndex - 1];
      if (!allowedEditColumns.includes(column?.field)) {
        this.errorToast('편집할 수 있는 셀이 아닙니다.');
        return;
      }
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
  },
};
</script>
